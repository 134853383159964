import React, { useEffect } from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import Metro from 'metro4';

function App() {
  useEffect(() => {
    console.log(Metro.toString)
  }, []);
  return (
    <div className="App">
      <nav data-role="ribbonmenu">
        <ul className="tabs-holder">
          <li className="static"><a href="#"><span className="mif-apps"></span></a></li>
          <li><a href="#section-one">One</a></li>
          <li><a href="#section-two">Two</a></li>
          <li><a href="#section-three">Three</a></li>
        </ul>

        <div className="content-holder">
            <div className="section" id="section-one">
                <p className="p-4">Section one</p>
            </div>
            <div className="section" id="section-two">
                <p className="p-4">Section two</p>
            </div>
            <div className="section" id="section-three">
                <p className="p-4">Section three</p>
            </div>
        </div>
      </nav>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
      </header>
    </div>
  );
}

export default App;
